.oi_app {
  text-align: center;
  width: 100%;
}

.oi_logo {
  transform-origin: 40px 40px;
}

.oi_logo_img {
  position: relative;
  height: 55px;
  width: 55px;
  display: inline-block;
  transform-origin: 0px 60px;
  left: -70px;
  top: -2px;
}

.oi_diag {
  position: relative;
  height: 62px;
  width: 60px;
  background-color: #e3ebff;
  /* border-radius: 40%; */
  transform: skewX(30deg);
  display: inline-block;
  left: 5px;
  top: 0px;
}

.oi_diag_inside {
  position: relative;
  height: 60px;
  width: 20px;
  background-color: rgba(3, 22, 90, 0.993);
  /* border-radius: 40%; */
  transform: skewX(30deg);
  display: inline-block;
  left: -80px;
  top: 0px;
}

.oi_menu {
  
}

h1, h2, h3, h4, h5, h6 {
  padding: 0px;
  margin: 0px;
}

h2 {
  color: rgb(3, 24, 80);
}

h3 {
  color: rgb(0, 21, 78);
}

h4 {
  color: rgb(20, 71, 141);
}

h4, h5, h6 {
  
}

.oi_header {
  background-color: white;
  width: 100%;
  height: 62px;
  /* box-shadow: inset 0px 55.5px 0px 0px #e9eefa; */
  border-bottom: 4px solid rgba(3, 22, 90, 0.993);
  display: flex;
  z-index: 3;
  flex-direction: column;
  color: black;
}

.oi_header_left {
  color:black;
  float: left;
  text-align: left;
  width: 50%;
}

.oi_header_title {
  position: relative;
  width: 400px;
  margin-left: 72px;
  top: -54px;
  font-weight: 900;
}

.oi_header_subtitle {
  position: relative;
  width: 400px;
  margin-left: 90px;
  top: -56px;
}

.oi_main {
  width: 80%;
  min-height: 100%;
  position: relative;
  border-radius: 2px;
  left: 10%;
  background-color: white;
}

.oi_main_body {
}

.oi_main_body_header {
  display: flex;
}

.oi_main_diag {
  width: 54%;
  height: 71px;
  top: 0%;
  z-index: 1;
  margin-left: 4%;
  transform: skew(-8deg);
  background-color: ghostwhite;
  position: relative;
}

.oi_main_nav_diag_1 {
  position: relative;
  color: white;
  
  width: 10%;
  height: 60px;
  background-color: midnightblue;
  transform: skew(-8deg);
}

.oi_main_nav_diag_2 {
  position: relative;
  color: white;
  width: 10%;
  height: 50px;
  background-color: rgb(13, 13, 83);
  transform: skew(-8deg);
}

.oi_contact_button {
  background-color: midnightblue;
  border: 2px groove midnightblue;
  width: 60%;
  height: 60px;
  margin-top: 10px;
  color: black;
  font-size: 80%;
  font-weight: bold;
  transform: rotateX(20deg);
  position:relative;
  left: 20%;
  pointer-events: auto;
}

.oi_schedule_button {
  background-color: midnightblue;
  border: 2px groove midnightblue;
  width: 60%;
  height: 60px;
  margin-top: 10px;
  display: block;
  color: #fff45b !important;
  font-size: 80%;
  font-weight: bold;
  transform: rotateX(20deg);
  position:relative;
  left: 18%;
  pointer-events: auto;
}

a {
  text-decoration: none;
  color: white;
}


li > a:hover {
  box-shadow: 2px  4px rgb(23, 23, 158);
  transition: .3s;
}

button {
  color: rgb(255, 255, 255) !important;
  background-color: midnightblue;
  font-size: 80%;
  font-weight: bold;
}

.oi_register_main {
  min-width: 200px;
  width: 40%;
  height: 60px;
  margin-bottom: 40px;
}

.oi_main_nav_diag_3 {
  position: relative;
  color: white;
  width: 10%;
  height: 40px;
  background-color: rgb(9, 9, 73);
  transform: skew(-8deg);
}

.oi_register {
  color: midnightblue !important;
  font-size: 80%;
  font-weight: bold;
  float:right;
}

.done {
  background-color: grey !important;
  color: white !important;
  pointer-events: none !important;
}

.date_done {
  color: grey !important;
  pointer-events: none !important;
}

.button_disabled {
  pointer-events: none;
}

.oi_register:hover {
  color: navy;
  text-decoration: underline;
  transition: all .3s ease-in-out;
}

.oi_main_body_header_text {
  color: white;
  font-weight: 400;
  font-style: bold;
  font-size: 220%;
  text-align: left;
  padding-left: 5%;
  padding-bottom: 8px;
  padding-top: 20px;
  background-color: rgb(41, 53, 122);
}

.oi_main_body_text {
  text-align: left;
  padding-left: 5%;
  padding-right: 3%;
  font-size: 120%;
  font-weight: 400;
  background-color: white;
  margin: 0px;
  padding-top: 20px;
  padding-bottom: 40px;
  width: 60%;
  text-align: justify;
  text-justify: inter-word;
}


.oi_main_text {
  width: 100%;
  display: flex;
  padding-top: 28px;
  padding-bottom: 8px;
}

.oi_upcoming_dates_header {
  text-align: center;
  font-size: larger;
}

input[type="datetime-local"]{
  height: 20px;
  width: 70%;
}

li::marker {
  width: 8px !important;
}

.oi_main_body_text_right {
  width: 30%;
  text-align: justify;
  text-justify: inter-word;
  font-size: 120%;
  margin-right: 2%;
  background-color: rgb(248, 248, 248);
  padding-bottom: 1.31%;
  margin-left: 2%;
  padding-left: 1%;
  padding-right: 1%;
  border-top: 2px solid lightgrey;
  border-bottom: 2px solid lightgrey;
}

.oi_main_content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow:auto;
  overflow-wrap: break-word;
  justify-content: space-between;
  background-color: rgb(249, 249, 249);
  border-top: 2px solid lightgrey;
  padding-top: 20px;
  min-height: 900px;
}

.oi_calendar {
  padding-bottom: 1em;
}

.oi_main_content_left {
  width: 100%;
  overflow-wrap: break-word;
  text-justify: newspaper;
  text-align: left;
  font-size: larger;
  padding-left: 60px;
  padding-right: 60px;
}

.oi_main_content_right {
  display: none;
  width: 24%;
  overflow-wrap: break-word;
  text-justify: newspaper;
  text-align: left;
  margin-right: 20px;
  font-size: larger;
}

.oi_footer {
  position: static;
  bottom: 0px;
  background-color: rgba(1, 13, 56, 0.993);
  height: 24px;
  width: 100%;
}

.oi_footer_text {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: smaller;
  color: white;
  margin: 0px;
}

.oi_calendar_title {
  padding-left: 2%;
  text-decoration: overline;
  text-decoration-color: rgba(217, 217, 238, 0.63);
  font-weight: 400;
  font-size: large;
  background-color: midnightblue;
  color: white;
}

.oi_calendar_header {
  background-color: white;
  padding-top: .5em;
  padding-bottom: .5em;
  border: 2px solid lightgray;
}

.App-link {
  color: #61dafb;
}

@media only screen and (max-width: 620px) {
  .oi_contact_button {
    width: 100%;
    left: 0%;
  }

  .oi_main_body_header_text {
    font-size: 160% !important;
  }

  .oi_main_body_text {
    padding-top: 40px !important;
  }

  .oi_main_content_left {
    padding-left: 20px;
    padding-right: 20px;
  }

  .oi_main_content_right {
    display: none !important;
  }
}

@media only screen and (max-width: 960px) {
  .oi_contact_button a {
     font-size: small;
  }

  .oi_main_body_header_text {
    font-size: 190%;
  }

  .oi_upcoming_dates_header {
    font-size: 90%;
  }
}

@media only screen and (max-width: 1026px) {
 .oi_main_body_text_right {
    display: none;
  }
  .oi_main_content_right {
    display:inline;
  }
  .oi_main_body_text {
    width: 90%;
    padding-top: 60px;
  }

  .oi_register {
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }

  input[type="datetime-local"] {
    width: 100% !important;
  }
  .oi_contact_button {
    width: 80%;
    left: 10%;
  }
}

@media only screen and (max-width: 1280px) {
  input[type="datetime-local"] {
  }
  .oi_register {
    text-align: center;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
