.calendar {
    padding-bottom: 2em;
}

.calendar ul, ol {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 1em;
  margin: 0 auto;
  max-width: 64em;
  padding: 0;
}
  
.cal_time {
  font-size: smaller;
  display: contents;
  color: aliceblue;
}

ol li {
  background-color: #eaeaea;
}

.active_hidden {
    padding: 0px;
    margin: 0px;
    display: contents;
}

.cal_register {
  background-color: midnightblue;
  width: 100%;
  height: 80%;
  padding-top: 30%
}

.cal_register_over {
  background-color: gray !important;
  width: 100%;
  height: 80%;
  padding-top: 30%
}

.calendar li {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin-left: 0;
  font-size: calc(16px + (21 - 16) * ((100vw - 300px) / (1600 - 300)));
}
  
.calendarul .weekdays {
  margin-bottom: 1em;
}
  
.calendar ul.weekdays li {
  height: 4vw;
}

.calendar ol.day-grid li {
  background-color: #ffffff;
  border: 1px solid #eaeaea;
  height: 12vw;
  max-height: 125px;
}

.calendar ul.weekdays abbr[title] {
  border: none;
  font-weight: 800;
  text-decoration: none;
}

.active {
  background-color: midnightblue;
  color: white;
  text-align: center;
}

.text_center {
  text-align: center;
}

.inactive {
  background-color: rgb(243, 243, 243) !important;
}

.active:hover {
  box-shadow: 2px  4px rgb(23, 23, 158);
  transition: .3s;
}

.cal_active {
  font-weight: bold;
  display: contents;
  text-decoration-style: solid;
}

.cal_active:hover {
  color: lightcyan;
}


@media all and (max-width: 1026px) {
    .calendar ul, ol {
        grid-gap: .25em;
    }
  
    .calendar ul.weekdays li {
        font-size: 0;
    }
  
    .calendar ul.weekdays > li abbr:after {
        content: attr(title);
        font-size: calc(16px + (26 - 16) * ((100vw - 300px) / (1600 - 300)));
        text-align: center;
    }

    .active_hidden {
        font-size: 80%;
    }
}

@media only screen and (max-width: 768px) {
    .cal_register {
        height: 40%;
    }
    .active_hidden {
        display: none !important;
    }
}